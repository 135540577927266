//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

// Api
import * as apiGeonames from '../api/geonames/geo';

// Models
import Location from '../models/location';

// Mixin
import errorsMixin from '../mixins/error-handler';

export default {
  name: 'SLocalisationWrapper',
  mixins: [errorsMixin],
  props: {
    sharedError: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      default: null,
      type: Object,
    },
    geoPending: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noSelectedError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      timeoutId: null,
      resetErrors: true,
      menuProps: {
        auto: true,
        contentClass: 'search-menu',
        maxHeight: 300,
      },
      dataSelected: null,
    };
  },
  computed: {
    ...mapGetters({
      getIsMobile: 'screen/getIsMobile',
    }),
  },
  watch: {
    selected(value) {
      this.dataSelected = value;
    },
    dataSelected(value) {
      if (value) {
        if (!this.items.find((elmt) => JSON.stringify(elmt) === JSON.stringify(value))) {
          this.items = [value];
        }
        this.errors$.handleError(false, 'geo', 'search');
      } else {
        this.items = [];
      }
      this.$emit('update:selected', value);
      this.$emit('update:no-selected-error', false);
    },
    sharedError() {
      this.initErrors();
    },
  },
  created() {
    this.dataSelected = this.selected;
  },
  mounted() {
    if (this.dataSelected && (!this.dataSelected.isPointable() || (this.dataSelected.isPointable() && this.dataSelected.city))) {
      this.items.push(this.dataSelected);
    }
    this.initErrors();
  },
  methods: {
    initErrors() {
      const nbrKeys = Object.keys(this.sharedError).length;
      if (nbrKeys > 0) {
        this.resetErrors = false;
      } else {
        this.errors$.handleError(false, 'geo', 'search');
      }
      this.errors$.mergeErrors(this.sharedError);
    },
    querySelections(value) {
      this.loading = true;

      this.errors$.reset();
      this.errors$.handleError(value.length > 100, 'geo', 'search');
      if (this.errors$.hasErrors()) {
        this.loading = false;
        return;
      }

      apiGeonames.byType('city', { search: value }, null).then((res) => {
        this.items = res.results.map((item) => new Location(item));
        this.loading = false;
      }).catch((error) => {
        // Validation error
        if (error.response && error.response.status === 400) {
          this.loading = false;
          this.errors$.handleError(true, 'geo', 'search', 'charlist');
        }
      });
    },
    search(value) {
      if ((value && !this.dataSelected) || (value && this.dataSelected && value !== this.dataSelected.inputText)) {
        this.resetErrors = true;
        this.items = [];
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.querySelections(value);
          this.$emit('update:no-selected-error', false);
        }, 250);
      } else if (this.resetErrors) {
        this.errors$.handleError(false, 'geo', 'search');
      }
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters, mapActions } from 'vuex';
// Plugins
import * as apiLoc from '../libraries/geolocation';
// Models
import Location from '../models/location';
import errorsMixin from '../mixins/error-handler';

export default {
  name: 'SSearchForm',
  mixins: [errorsMixin],
  data() {
    return {
      geoPending: false,
      showGeoLoc: true,
      currentSearch: null,
      lastSearch: null,
      noSelectedError: false,
      loadingSearch: false,
    };
  },
  computed: {
    ...mapGetters({
      getCurrentSearch: 'transferData/getCurrentSearch',
    }),
  },
  watch: {
    currentSearch(value) {
      if (value && (!value.geoSearch || value === this.lastSearch)) {
        this.search();
      }
    },
  },
  mounted() {
    if (this.getCurrentSearch) {
      this.lastSearch = new Location(this.getCurrentSearch);
    }
  },
  methods: {
    ...mapActions({
      updateContext: 'transferData/updateContext',
      updateCurrentSearch: 'transferData/updateCurrentSearch',
    }),
    search() {
      if (!this.currentSearch) {
        this.noSelectedError = true;
        return;
      }
      this.errors$.reset('geo');
      this.loadingSearch = true;
      this.updateContext('list');
      this.updateCurrentSearch(new Location(this.currentSearch));
      const place = this.currentSearch.city.alias;
      const admin = this.currentSearch.admin ? this.currentSearch.admin.alias : this.currentSearch.country.alias;
      if (this.currentSearch.geoSearch) {
        const { lat, lon } = this.currentSearch.location;
        const userLocation = this.currentSearch.userLocation;
        if (this.$matomo) {
          this.$matomo.trackEvent('Search', 'geolocation', `${place} - lat: ${lat}, lon: ${lon}`);
        }
        this.$newRouter.push({
          name: 'list-city',
          params: {
            admin, place, userLocation,
          },
          query: { lat, lon },

        });
      } else {
        if (this.$matomo) {
          this.$matomo.trackEvent('Search', 'autocomplete', place);
        }
        this.$newRouter.push({ name: 'list-city', params: { admin, place } });
      }
    },

    // Géolocalisation de l'utilisateur
    updateGeolocation() {
      this.noSelectedError = false;
      this.geoPending = true;
      apiLoc.geolocation(true, this).then((data) => {
        this.errors$.reset();
        if (data.type === 4) data.type = 3;
        this.currentSearch = data;
      }).catch(() => {
        if (this.errors$.get('geo', 'position')) {
          this.$nextTick(() => {
            this.$refs['geo-position']?.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          });
        }
      }).finally(() => {
        this.geoPending = false;
      });
    },
    loadLastSearch() {
      this.loadingSearch = true;
      this.currentSearch = this.lastSearch;
    },
  },
};

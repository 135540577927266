//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';

// Mixins
import metaMixin from '../mixins/meta';

export default {
  name: 'Search',
  mixins: [metaMixin],
  head() {
    return {
      title: 'Aire de jeux gratuite autour de moi',
      meta: this.meta([{
        hid: 'description',
        name: 'description',
        content: 'Trouvez une aire de jeux gratuite près de chez vous !',
      }, {
        hid: 'image',
        name: 'image',
        content: `${process.env.VUE_APP_URL}img/socials/default.png`,
      }]),
      link: [{
        rel: 'canonical',
        href: `${process.env.VUE_APP_URL}`,
      }],
    };
  },
  computed: {
    ...mapGetters({
      isPwa: 'preferences/isPwa',
    }),
    breadcrumbMicrodatas() {
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [{
          '@type': 'ListItem',
          position: 1,
          name: 'Accueil | ADJ',
          item: `${process.env.VUE_APP_URL}`,
        }, {
          '@type': 'ListItem',
          position: 2,
          name: 'Rechercher une aire de jeux | ADJ',
          item: `${process.env.VUE_APP_URL}search`,
        }],
      };
    },
  },
  created() {
    if (process.client && this.isPwa) {
      if (!window.history.state?.goFirst && !window.history.state?.askExit && !window.history.state?.ready) {
        this.$newRouter.replaceState({ goFirst: true }, 'Recherche', '/recherche');
        this.$newRouter.pushState({ ready: true }, 'Recherche', '/recherche');
      }
    }
  },
};
